/*MODIFY STORE CATEGORIES STYLE*/
.rti--container {
    background-color: #fff;
}
.rti--tag {
    border-radius: 20px;
    border: 1px solid rgb(206, 212, 218);
    background-color: #fff;
}
.rti--container span {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    padding: 3px 6px;
    font-size: 13px;
}
.rti--input {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 14px;
}